import React from "react";
import { Container } from "@mui/material";
import "./styles.scss";
import Header from "../../components/header";
import { Typography } from "@mui/material";

const WodorPage = () => {
	const lang = sessionStorage.getItem("language") === "en";
	const links = [{ href: "/kontakt", text: lang ? "Contact" : "Kontakt" }];

	return (
		<>
			<Header links={links} />
			<div className="wodor">
				{lang ? (
					<Typography
						variant="h2"
						component="p"
						gutterBottom
						align="center"
					>
						Hydrogen Projects
					</Typography>
				) : (
					<Typography
						variant="h2"
						component="p"
						gutterBottom
						align="center"
					>
						Projekty Wodorowe
					</Typography>
				)}
			</div>
			<Container className="text">
				{lang ? (
					<>
						{/* <Typography
							variant="h2"
							component="p"
							gutterBottom
							align="center"
						>
							Hydrogen Projects
						</Typography> */}

						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							Locus Tech is involved in Energy Transformation
							based on renewable energy sources.
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							Along with Polish research centres, we are working
							on new projects based on energy storage in the form
							of high-pressure hydrogen storage, obtained by water
							electrolysis or gas reforming.
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							We are currently working on more projects, which
							will soon be put into implementation.
						</Typography>
						<ol>
							<li>
								<Typography
									variant="h6"
									component="section"
									gutterBottom
								>
									1. Small hydrogen storage facilities for
									individual customers with DC-side connection
									power up to 100 kW
								</Typography>
							</li>
							<li>
								<Typography
									variant="h6"
									component="section"
									gutterBottom
								>
									2. Large hydrogen and oxygen storage
									facilities for off grid PV and wind power
									installations of 30 to 500 MWp
								</Typography>
							</li>
							<li>
								<Typography
									variant="h6"
									component="section"
									gutterBottom
								>
									3. Safe storage and burning of hydrogen for
									consumers.
								</Typography>
							</li>
						</ol>
					</>
				) : (
					<>
						{/* <Typography
							variant="h2"
							component="p"
							gutterBottom
							align="center"
						>
							Projekty Wodorowe
						</Typography> */}

						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							Locus Tech jest zaangażowany w Transformacje
							Energetyczną opartą o odnawialne źródła energii.
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							Wraz z polskimi ośrodkami naukowymi pracujemy nad
							nowymi projektami opartymi o magazynowanie energii w
							postaci wysokociśnieniowych magazynów wodoru,
							pozyskiwanego na drodze elektrolizy wody lub
							reformingu gazowego.
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							Obecnie pracujemy nad kolejnymi projektami, które
							wkrótce będziemy wdrażać do realizacji.
						</Typography>
						<ol>
							<li>
								<Typography
									variant="h6"
									component="section"
									gutterBottom
								>
									Małe instalacje magazynów wodoru dla
									klientów indywidualnych o mocy
									przyłączeniowej po stronie DC do 100 kW
								</Typography>
							</li>
							<li>
								<Typography
									variant="h6"
									component="section"
									gutterBottom
								>
									Duże instalacje magazynów wodoru i tlenu dla
									off grid instalacji PV oraz wiatrowych o
									mocy od 30 do 500 MWp
								</Typography>
							</li>
							<li>
								<Typography
									variant="h6"
									component="section"
									gutterBottom
								>
									Bezpieczne magazynowanie i spalanie wodoru
									dla konsumentów.
								</Typography>
							</li>
						</ol>
					</>
				)}
			</Container>
		</>
	);
};

export default WodorPage;
