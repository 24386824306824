import React from "react";
import "./App.scss";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "./pages/home";
import TransportPage from "./pages/transport";
import WodorPage from "./pages/wodor";
import Przewozy from "./pages/przewozy";
import UnderConstruction from "./components/underConstruction";
import Kontakt from "./pages/kontakt";
import Kolejowa from "./pages/kolejowa";
import Ladowa from "./pages/srodladowa";
import LancuchyDostaw from "./pages/lancuchy-dostaw";

const App = () => {
	return (
		<BrowserRouter>
				<Routes>
					<Route path="/" element={<WodorPage />} />
					<Route path="/logistyka" element={<TransportPage />} />
					<Route
						path="/technologie-wodorowe"
						element={<WodorPage />}
					/>
					<Route path="/spedycja-morska" element={<Przewozy />} />
					<Route
						path="/under-construction"
						element={<UnderConstruction />}
					/>
					<Route path="/kontakt" element={<Kontakt />} />
					<Route path="/spedycja-kolejowa" element={<Kolejowa />} />
					<Route path="/spedycja-ladowa" element={<Ladowa />} />
					<Route
						path="/lancuch-dostaw"
						element={<LancuchyDostaw />}
					/>
				</Routes>
		</BrowserRouter>
	);
};

export default App;
