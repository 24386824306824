import React from "react";
import { Container, Typography } from "@mui/material";
import "./styles.scss";
import Header from "../../components/header";
import { Link } from "react-router-dom";

const LancuchyDostaw = () => {
	const lang = sessionStorage.getItem("language") === "en";
	const links = [
		{ href: "/spedycja-morska", text: lang ? "Sea freight shipping": "Spedycja morska" },
		{ href: "/spedycja-kolejowa", text: lang ? "Rail transportation": "Spedycja kolejowa" },
		{ href: "/spedycja-ladowa", text: lang ? "Inland transportation": "Spedycja śródlądowa" },
		{ href: "/lancuch-dostaw", text: lang ? "Supply chains": "Łańcuch dostaw" },
		{ href: "/kontakt", text: lang ? "Contact": "Kontakt" },
	];
	
	return (
		<>
			<Header links={links} />
			<div className="lancuch"></div>
			<Container className="text">
				{lang ? (
					<>
						<Typography
							variant="h2"
							component="p"
							gutterBottom
							align="center"
						>
							Supply Chains
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							We design complete supply chains for customers -
							from the production floor to the end customer.
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							Today's supply chains are becoming increasingly
							complex. Commodities from the moment they are
							manufactured to the moment they reach the end
							consumer often change packaging, mode of transport,
							type of storage, their volume and weight. This
							requires precise design, implementation and
							continuous monitoring of product supply chains.
							Supply chain disruptions and inefficiencies generate
							wasted time and money. It also interferes with
							companies' ability to maintain a flexible supply
							chain that can meet consumers' growing need for
							convenient and near-instant access to safer, fresher
							goods and services.
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							To overcome these challenges, companies need full
							control of their extensive supply chains from
							beginning to end of the cycle. Our company will
							design and monitor your interconnected supply chain
							for you. Our years of experience in monitoring these
							processes will allow you to optimize and eliminate
							their weak and costly elements. We will also provide
							insights into business partners, systems and
							processes to proactively identify problems and make
							optimal decisions in real time.
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							Locus-Tech helps companies connect their entire
							supply chain network and protect their products,
							brands and consumers by ensuring the integrity and
							timely movement of their cargo.
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
							align="center"
						>
							<u>
								<Link to="/kontakt" className="link">
									Ask us about supply chain design.
								</Link>
							</u>
						</Typography>
					</>
				) : (
					<>
						<Typography
							variant="h2"
							component="p"
							gutterBottom
							align="center"
						>
							Łańcuch dostaw{" "}
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							Projektujemy dla klientów kompletne łańcuchy dostaw
							– od hali produkcyjnej aż do odbiorcy końcowego.
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							Współczesne łańcuchy dostaw są coraz bardziej
							złożone. Towar od momentu wytworzenia do chwili
							dotarcia do konsumenta końcowego zmienia często
							opakowanie, środek transportu, rodzaj magazynu,
							swoją objętość i wagę. Wymaga to precyzyjnego
							projektowania, wdrażania i ciągłego monitorowania
							łańcuchów dostaw produktu. Zakłócenia i
							nieefektywność łańcucha dostaw generuje stratę czasu
							i pieniędzy. Koliduje to również ze zdolnością
							przedsiębiorstw do utrzymywania elastycznego
							łańcucha dostaw, który może zaspokoić rosnące
							potrzeby konsumentów w zakresie wygodnego i niemal
							natychmiastowego dostępu do bezpieczniejszych,
							świeższych towarów i usług.
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							Aby sprostać tym wyzwaniom, firmy potrzebują pełnej
							kontroli swoich rozbudowanych łańcuchów dostaw od
							początku do końca cyklu. Nasza firma zaprojektuje i
							będzie monitorować dla Państwa połączony łańcuch
							dostaw. Nasze wieloletnie doświadczenie w
							monitorowaniu tych procesów pozwoli państwu
							zoptymalizować i wyeliminować słabe i kosztowne
							elementy tych łańcuchów. Zapewnimy także wgląd w
							informacje dotyczące partnerów biznesowych, systemów
							i procesów w celu proaktywnego identyfikowania
							problemów i podejmowania optymalnych decyzji w
							czasie rzeczywistym.
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							Locus - Tech pomaga firmom połączyć całą sieć
							łańcucha dostaw i chronić swoje produkty, marki i
							konsumentów zapewniając integralność i terminowe
							przemieszczanie ich ładunku.
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
							align="center"
						>
							<u>
								<Link to="/kontakt" className="link">
									Zapytaj nas o projekt łańcucha dostaw.
								</Link>
							</u>
						</Typography>
					</>
				)}
			</Container>
		</>
	);
};

export default LancuchyDostaw;
