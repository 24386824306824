import React from "react";
import { Container, Typography } from "@mui/material";
import "./styles.scss";
import Header from "../../components/header";

const TransportPage = () => {
	const lang = sessionStorage.getItem("language") === "en";
	const links = [
		{ href: "/spedycja-morska", text: lang ? "Sea freight shipping": "Spedycja morska" },
		{ href: "/spedycja-kolejowa", text: lang ? "Rail transportation": "Spedycja kolejowa" },
		{ href: "/spedycja-ladowa", text: lang ? "Inland transportation": "Spedycja śródlądowa" },
		{ href: "/lancuch-dostaw", text: lang ? "Supply chains": "Łańcuch dostaw" },
		{ href: "/kontakt", text: lang ? "Contact": "Kontakt" },
	];
	
	return (
		<>
			<Header links={links} />
			<div className="statek"></div>
			<Container className="text">
				{lang ? (
					<>
						<Typography
							variant="h2"
							component="p"
							gutterBottom
							align="center"
						>
							Transport
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							We specialize in sea freight forwarding services and
							offer comprehensive services for both export and
							import of goods. We have access to the quays in the
							port of Szczecin and Świnoujście. We encourage you
							to read the detailed description the types of berths
							available and their parameters i reloading
							possibilities. We also make it possible use of port
							warehouses with which we cooperate. We provide
							expert advice on sea transport and we help you
							choose the right means of transport to minimize
							costs and meet customer expectations.
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							We recommend using the service of our insurance
							broker cargo, which allows you to secure the loading
							in an accident random events. Customer satisfaction
							is there for we are the most important and we follow
							this principle at Locus - Tech. If you are looking
							for a company with many years of experience in sea
							forwarding, we encourage you to contact us and use
							our services. We provide safe and timely services
							sea freight tailored to individual needs
							contractors. We invite you to cooperation.
						</Typography>
					</>
				) : (
					<>
						<Typography
							variant="h2"
							component="p"
							gutterBottom
							align="center"
						>
							Transport
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							Specjalizujemy się w usługach spedycji morskiej i
							oferujemy kompleksowe usługi zarówno eksportu, jak i
							importu towaru. Posiadamy dostęp do nabrzeży w
							porcie w Szczecinie i Świnoujściu. Zachęcamy do
							zapoznania się z dokładnym opisem rodzajów
							dostępnych rodzajów nabrzeży ich parametrami i
							możliwościami przeładunkowymi. Umożliwiamy także
							skorzystanie z magazynów portowych z którymi
							współpracujemy. Zapewniamy fachowe porady odnośnie
							transportu morskiego i pomagamy wybrać odpowiedni
							środek transportu, by zminimalizować koszty i
							spełnić oczekiwania klientów.
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							Polecamy skorzystanie z usługi naszego brokera
							ubezpieczenia cargo, które umożliwia zabezpieczenie
							załadunku na wypadek wystąpienia zdarzeń losowych.
							Zadowolenie klientów jest dla nas najważniejsze i tą
							zasadą kierujemy się w Locus - Tech. Jeśli szukają
							Państwo firmy z wieloletnim doświadczeniem w
							spedycji morskiej to zachęcamy do kontaktu i
							skorzystania z naszych usług. Świadczymy bezpieczne
							i terminowe usługi spedycji morskiej dopasowane do
							indywidualnych potrzeb kontrahentów. Zapraszamy do
							współpracy.
						</Typography>
					</>
				)}
			</Container>
		</>
	);
};

export default TransportPage;
