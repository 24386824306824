import React from "react";

import { Container, Typography } from "@mui/material";
import "./styles.scss";
import Header from "../../components/header";
import { Link } from "react-router-dom";

const Ladowa = () => {
	const lang = sessionStorage.getItem("language") === "en";
	const links = [
		{
			href: "/spedycja-morska",
			text: lang ? "Sea freight shipping" : "Spedycja morska",
		},
		{
			href: "/spedycja-kolejowa",
			text: lang ? "Rail transportation" : "Spedycja kolejowa",
		},
		{
			href: "/spedycja-ladowa",
			text: lang ? "Inland transportation" : "Spedycja śródlądowa",
		},
		{
			href: "/lancuch-dostaw",
			text: lang ? "Supply chains" : "Łańcuch dostaw",
		},
		{ href: "/kontakt", text: lang ? "Contact" : "Kontakt" },
	];

	return (
		<>
			<Header links={links} />
			<div className="barka"></div>
			<Container className="text">
				{lang ? (
					<>
						<Typography
							variant="h2"
							component="p"
							gutterBottom
							align="center"
						>
							Inland transportation
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							Our company operates in the ports of Szczecin and
							Świnoujście, which have a unique in the country
							river transport connection with river ports in
							Wrocław, Opole and Gliwice as well as with Germany,
							the Netherlands and Belgium. Responding to the needs
							of our customers, we organize barge transportation
							from the depths of Europe to the port, as well as
							from the ports to transshipment terminals inside the
							continent. Barge transport, next to sea transport,
							is not only one of the cheapest ways to move goods,
							it is also one of the most environmentally friendly
							methods of shipment. The barges of our carriers can
							take 500, 1000 or even 2000 tons of goods at a time.
							Ask us about barge transportation.
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
							align="center"
						>
							<u>
								<Link to="/kontakt" className="link">
									Ask us about inland transportation.
								</Link>
							</u>
						</Typography>
					</>
				) : (
					<>
						<Typography
							variant="h2"
							component="p"
							gutterBottom
							align="center"
						>
							Transport śródlądowy
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							Nasza firma działa na terenie portów Szczecina i
							Świnoujścia, które mają unikatowe w skali kraju
							rzeczne połączenie transportowe z portami rzecznymi
							we Wrocławiu, Opolu i Gliwicach a także z Niemcami,
							Holandią i Belgią. Odpowiadając na potrzeby naszych
							klientów organizujemy przewozy barkowe z głębi
							Europy do portu, jak również z portów do terminali
							przeładunkowych wewnątrz kontynentu. Transport
							barkowy, obok transportu morskiego, jest nie tylko
							jednym z najtańszych sposobów przemieszczania
							towarów, należy również do najbardziej z
							ekologicznych metod przewozu. Barki naszych
							przewoźników mogą jednorazowo zabrać 500, 1000 a
							nawet 2000 ton towaru. Zapytaj nas o przewozy
							barkowe.
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
							align="center"
						>
							<u>
								<Link to="/kontakt" className="link">
									Zapytaj nas o transport śródlądowy.
								</Link>
							</u>
						</Typography>
					</>
				)}
			</Container>
		</>
	);
};

export default Ladowa;
