import React from "react";
import { Container, Typography } from "@mui/material";
import "./styles.scss";
import Header from "../../components/header";
import { Link } from "react-router-dom";

const Kolejowa = () => {
	const lang = sessionStorage.getItem("language") === "en";
	const links = [
		{ href: "/spedycja-morska", text: lang ? "Sea freight shipping": "Spedycja morska" },
		{ href: "/spedycja-kolejowa", text: lang ? "Rail transportation": "Spedycja kolejowa" },
		{ href: "/spedycja-ladowa", text: lang ? "Inland transportation": "Spedycja śródlądowa" },
		{ href: "/lancuch-dostaw", text: lang ? "Supply chains": "Łańcuch dostaw" },
		{ href: "/kontakt", text: lang ? "Contact": "Kontakt" },
	];

	return (
		<>
			<Header links={links} />
			<div className="kolej"></div>

			<Container className="text">
				{lang ? (
					<>
						<Typography
							variant="h2"
							component="p"
							gutterBottom
							align="center"
						>
							Rail transportation
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							We organize rail transportation for our customers to
							and from ports in the Baltic Sea. We work with both
							domestic and foreign carriers. We have various types
							of rolling stock: coal cars, container platforms,
							grain silos, rail tank cars, ISO tanks, etc. We
							provide a complete supply chain for our customers,
							from the production warehouse to the final supplier.
							Rail is an important part of this process.
							Especially when dealing with bulk cargo, which is
							most efficiently moved on land by track or inland
							transportation.
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
							align="center"
						>
							<u>
								<Link to="/kontakt" className="link">
									Ask us about rail transportation.
								</Link>
							</u>
						</Typography>
					</>
				) : (
					<>
						<Typography
							variant="h2"
							component="p"
							gutterBottom
							align="center"
						>
							Transport kolejowy
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
						>
							Organizujemy dla naszych klientów transporty
							kolejowe do i z portów na Bałtyku. Współpracujemy z
							przewoźnikami krajowymi jak i zagranicznymi.
							Dysponujemy różnymi rodzajami taboru: wagony
							węglowe, platformy kontenerowe, silosy zbożowe,
							cysterny kolejowe, ISO tanki itp. Zapewniamy
							kompletny łańcuch dostaw dla naszych klientów, od
							magazynu produkcyjnego do końcowego dostawcy.
							Istotnym elementem tego procesu jest kolej.
							Szczególnie przy ładunkach masowych, których
							przemieszczanie po lądzie jest najbardziej efektywne
							po torach lub transportem śródlądowym.
						</Typography>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
							align="center"
						>
							<u>
								<Link to="/kontakt" className="link">
									Zapytaj nas o transport kolejowy.
								</Link>
							</u>
						</Typography>
					</>
				)}
			</Container>
		</>
	);
};

export default Kolejowa;
