import React from "react";
import { Container, Typography } from "@mui/material";
import "./styles.scss";
import Header from "../../components/header";
import { Link } from "react-router-dom";

const Przewozy = () => {
	const lang = sessionStorage.getItem("language") === "en";
	const links = [
		{ href: "/spedycja-morska", text: lang ? "Sea freight shipping": "Spedycja morska" },
		{ href: "/spedycja-kolejowa", text: lang ? "Rail transportation": "Spedycja kolejowa" },
		{ href: "/spedycja-ladowa", text: lang ? "Inland transportation": "Spedycja śródlądowa" },
		{ href: "/lancuch-dostaw", text: lang ? "Supply chains": "Łańcuch dostaw" },
		{ href: "/kontakt", text: lang ? "Contact": "Kontakt" },
	];

	return (
		<>
			<Header links={links} />
			<div className="morska"></div>
			<Container className="text">
				{lang ? (
					<>
						<Typography
							variant="h2"
							component="p"
							gutterBottom
							align="center"
						>
							Sea freight shipping
						</Typography>
						<ul>
							<li>
								<Typography
									variant="h6"
									component="section"
									gutterBottom
								>
									We handle import, export and transit cargoes
								</Typography>
							</li>
							<li>
								<Typography
									variant="h6"
									component="section"
									gutterBottom
								>
									We organize sea transportation, storage,
									warehousing at the port
								</Typography>
							</li>
							<li>
								<Typography
									variant="h6"
									component="section"
									gutterBottom
								>
									We handle bulk cargoes, LCL palletized
									general cargoes, conventional general
									cargoes, packaging of goods, transportation
									of oversized cargoes, food cargoes including
									refrigerated cargoes
								</Typography>
							</li>
							<li>
								<Typography
									variant="h6"
									component="section"
									gutterBottom
								>
									We arrange efficient booking of places on
									ocean-going and feeder ships
								</Typography>
							</li>
							<li>
								<Typography
									variant="h6"
									component="section"
									gutterBottom
								>
									We arrange port, documentary and customs
									services (phytosanitary, veterinary,
									sanitary and WIJHARS inspections)
								</Typography>
							</li>
						</ul>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
							align="center"
						>
							<u>
								<Link to="/kontakt" className="link">
									Ask us about port services.
								</Link>
							</u>
						</Typography>
					</>
				) : (
					<>
						<Typography
							variant="h2"
							component="p"
							gutterBottom
							align="center"
						>
							Spedycja morska
						</Typography>
						<ul>
							<li>
								<Typography
									variant="h6"
									component="section"
									gutterBottom
								>
									Obsługujemy ładunki w imporcie, eksporcie
									oraz tranzycie
								</Typography>
							</li>
							<li>
								<Typography
									variant="h6"
									component="section"
									gutterBottom
								>
									Organizujemy transport morski, składowanie,
									magazynowanie w porcie
								</Typography>
							</li>
							<li>
								<Typography
									variant="h6"
									component="section"
									gutterBottom
								>
									Zajmujemy się ładunkami masowymi bulk,
									drobnicy paletyzowanej LCL, drobnicy
									konwencjonalnej, konfekcjonowaniem towarów,
									transportem ładunków ponadgabarytowych,
									spożywczych w tym chłodniczych
								</Typography>
							</li>
							<li>
								<Typography
									variant="h6"
									component="section"
									gutterBottom
								>
									Aranżujemy sprawną rezerwację miejsc na
									statkach oceanicznych i dowozowych
								</Typography>
							</li>
							<li>
								<Typography
									variant="h6"
									component="section"
									gutterBottom
								>
									Organizujemy obsługę portową, dokumentacyjną
									i celną (kontrole fitosanitarne,
									weterynaryjne, Sanepid oraz WIJHARS)
								</Typography>
							</li>
						</ul>
						<Typography
							variant="h6"
							component="section"
							gutterBottom
							align="center"
						>
							<u>
								<Link to="/kontakt" className="link">
									Zapytaj nas o usługi portowe.
								</Link>
							</u>
						</Typography>
					</>
				)}
			</Container>
		</>
	);
};

export default Przewozy;
