import React, { useState } from "react";
import { Typography, Container, Drawer } from "@mui/material";
import "./styles.scss";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
// import Logo from "../../assets/logo.png";
import NoweLogo from "../../assets/nowe_logo.png";

const Header = ({ links }) => {
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const switchLanguage = (lang) => {
		sessionStorage.setItem("language", lang.target.value);
		window.location.reload(false);
	};

	return (
		<Container>
			<div className="header">
				<div
					className="logo"
					onClick={() => {
						navigate("/");
					}}
				>
					<img src={NoweLogo} alt="Locus" />
				</div>
				<div className="flex">
					<div className="links">
						{links.map((link, index) => (
							<Typography
								variant="h5"
								gutterBottom
								component="div"
								className="link"
								key={link + index}
							>
								<Link to={link.href}>{link.text}</Link>
							</Typography>
						))}
					</div>
					<div className="language-switch">
						<button
							onClick={switchLanguage}
							className="language"
							value="en"
						>
							EN
						</button>
						<button
							onClick={switchLanguage}
							className="language"
							value="pl"
						>
							PL
						</button>
					</div>
				</div>
				<div className="links-mobile">
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="end"
						onClick={handleDrawerOpen}
						sx={{ ...(open && { display: "none" }) }}
					>
						<MenuIcon />
					</IconButton>
				</div>
			</div>
			<Drawer variant="temporary" anchor="right" open={open}>
				<div>
					<IconButton onClick={handleDrawerClose}>
						<ChevronRightIcon />
					</IconButton>
				</div>
				<Divider />
				<div className="links">
					{links.map((link, index) => (
						<Typography
							variant="h5"
							gutterBottom
							component="div"
							className="link"
							key={link + index}
						>
							<Link to={link.href}>{link.text}</Link>
						</Typography>
					))}
					<div className="language-switch-mobile">
						<button
							onClick={switchLanguage}
							className="language"
							value="en"
						>
							EN
						</button>
						<button
							onClick={switchLanguage}
							className="language"
							value="pl"
						>
							PL
						</button>
					</div>
				</div>
			</Drawer>
		</Container>
	);
};

export default Header;
