import React, { useState } from "react";
import { Container, Button, TextField, Typography } from "@mui/material";
import Header from "../../components/header";
import emailjs from "@emailjs/browser";
import "./styles.scss";

const PostContactForm = async (values, successCallback, errorCallback) => {
	emailjs
		.send(
			"service_f7tb0fl",
			"template_29vnx7c",
			values,
			"xN8mM7uusuf6zk9Vd"
		)
		.then(
			function (response) {
				// console.log("SUCCESS!", response.status, response.text);
			},
			function (err) {
				console.log("FAILED...", err);
			}
		);

	if (true) {
		successCallback();
	} else {
		errorCallback();
	}
};

const initialFormValues = {
	fullName: "",
	email: "",
	message: "",
	formSubmitted: false,
	success: false,
};

export const useFormControls = () => {
	const [values, setValues] = useState(initialFormValues);
	const [errors, setErrors] = useState({});
	const lang = sessionStorage.getItem("language") === "en";

	const validate = (fieldValues = values) => {
		let temp = { ...errors };

		if ("fullName" in fieldValues)
			temp.fullName = fieldValues.fullName
				? ""
				: lang
				? "Field required"
				: "Pole wymagane.";

		if ("email" in fieldValues) {
			temp.email = fieldValues.email
				? ""
				: lang
				? "Field required"
				: "Pole wymagane.";
			if (fieldValues.email)
				temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(
					fieldValues.email
				)
					? ""
					: lang
					? "Email is not valid"
					: "Email niepoprawny.";
		}

		if ("message" in fieldValues)
			temp.message =
				fieldValues.message.length !== 0
					? ""
					: lang
					? "Field required"
					: "Pole wymagane.";

		setErrors({
			...temp,
		});
	};

	const handleInputValue = (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});
		validate({ [name]: value });
	};

	const handleSuccess = () => {
		setValues({
			...initialFormValues,
			formSubmitted: true,
			success: true,
		});
	};

	const handleError = () => {
		setValues({
			...initialFormValues,
			formSubmitted: true,
			success: false,
		});
	};

	const formIsValid = (fieldValues = values) => {
		const isValid =
			fieldValues.fullName &&
			fieldValues.email &&
			fieldValues.message &&
			Object.values(errors).every((x) => x === "");

		return isValid;
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		const isValid =
			Object.values(errors).every((x) => x === "") && formIsValid();
		if (isValid) {
			await PostContactForm(values, handleSuccess, handleError);
		}
	};

	return {
		values,
		errors,
		handleInputValue,
		handleFormSubmit,
		formIsValid,
	};
};

const Kontakt = () => {
	const { handleInputValue, handleFormSubmit, formIsValid, errors } =
		useFormControls();
	const lang = sessionStorage.getItem("language") === "en";
	const links = [
		{ href: "/", text: lang ? "Home": "Home" },
	];

	const inputFieldValues = [
		{
			name: "fullName",
			label: lang ? "First name and last name" : "Imię i nazwisko",
			id: "my-name",
		},
		{
			name: "email",
			label: "Email",
			id: "my-email",
		},
		{
			name: "message",
			label: lang ? "Message" : "Wiadomość",
			id: "my-message",
			multiline: true,
			rows: 10,
		},
	];

	return (
		<>
			<Header links={links} />

			<div className="wodor">
				{lang ? (
					<Typography
						variant="h2"
						component="p"
						gutterBottom
						align="center"
					>
						Contact
					</Typography>
				) : (
					<Typography
						variant="h2"
						component="p"
						gutterBottom
						align="center"
					>
						Kontakt
					</Typography>
				)}
			</div>
			<Container>
				<form
					autoComplete="off"
					onSubmit={handleFormSubmit}
					className="contact-form"
				>
					{inputFieldValues.map((inputFieldValue, index) => {
						return (
							<TextField
								key={index}
								onChange={handleInputValue}
								onBlur={handleInputValue}
								name={inputFieldValue.name}
								label={inputFieldValue.label}
								error={errors[inputFieldValue.name]}
								multiline={inputFieldValue.multiline ?? false}
								fullWidth
								rows={inputFieldValue.rows ?? 1}
								autoComplete="none"
								{...(errors[inputFieldValue.name] && {
									error: true,
									helperText: errors[inputFieldValue.name],
								})}
							/>
						);
					})}
					<Button
						variant="contained"
						type="submit"
						color="secondary"
						disabled={!formIsValid()}
					>
						{lang ? "Send" : "Wyślij"}
					</Button>
				</form>
			</Container>
		</>
	);
};

export default Kontakt;
