import React from "react";
import Header from "../header";
import { Container } from "@mui/system";
import "./styles.scss";
import underConstruction from "../../assets/under-construction-sign-vector.jpg";

const links = [
	{ href: "/spedycja-morska", text: "Spedycja morska" },
	{ href: "/spedycja-kolejowa", text: "Spedycja kolejowa" },
	{ href: "/spedycja-ladowa", text: "Spedycja śródlądowa" },
	{ href: "/lancuch-dostaw", text: "Łańcuch dostaw" },
	{ href: "/kontakt", text: "Kontakt" },
];

const UnderConstruction = () => {
	return (
		<>
			<Header links={links} />
			<Container>
				<div className="under-construction">
					<h4>Strona w budowie</h4>
					<img
						src={underConstruction}
						alt="Strona w budowie"
						className="under-construction"
					/>
					<p>
						<i>Zapraszamy niedługo w nowej odsłonie</i>
					</p>
				</div>
			</Container>
		</>
	);
};

export default UnderConstruction;
